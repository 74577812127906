import React from 'react';
import './App.css';
import { LoginScreen } from "./views/LoginScreen";
import { FirstScanScreen } from "./views/FirstScanScreen";
import { Route, HashRouter } from "react-router-dom";
import { HomeScreen } from "./views/HomeScreen";
import { ScanScreen } from './views/ScanScreen';
import {useCookies} from "react-cookie";
import Modal from 'react-modal';
import {ScoreboardScreen} from "./views/ScoreboardScreen";
import {ChipActionScreen} from "./views/ChipActionScreen";

Modal.setAppElement('#root')

function App() {
    const [cookies, setCookie] = useCookies();
    if(!cookies.totalChips) setCookie("totalChips", 0)

    return (
        <div className="App">
            <HashRouter>
                <div className="App-header">
                    <Route exact path="/" component={HomeScreen} />
                    <Route exact path="/firstscan" component={FirstScanScreen} />
                    <Route exact path="/scan/:chipId" component={ScanScreen} />
                    <Route path="/login" component={LoginScreen} />
                    <Route path="/scoreboard" component={ScoreboardScreen} />
                    <Route path="/action/:chipId" component={ChipActionScreen} />
                </div>
            </HashRouter>
        </div>
    );
}

export default App;
