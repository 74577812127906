import React, {useEffect} from "react";
import { InfoHeader}  from "../components/InfoHeader";
import { ChipList } from "../components/ChipList";
import { Link } from "react-router-dom";

export function FirstScanScreen(){
    const userInStorage = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        // If there is a logged in user or a user with >1 scan or a user that hasn't done anything yet, he shouldn't belong here
        if(userInStorage && (userInStorage.name !== "" || (userInStorage.scans && userInStorage.scans.length > 1))){
            window.open(process.env.REACT_APP_LOCAL_BASE_URL + '/#/', '_self');
        } else if(!userInStorage || (userInStorage && userInStorage.scans && userInStorage.scans.length === 0)){
            window.open(process.env.REACT_APP_LOCAL_BASE_URL + '/#/login', '_self');
        }
    });

    return (
        <div>
            <InfoHeader title="Eerste sleutel gevonden!" content="Yes! Je hebt zojuist de eerste sleutel gescand. Tap op de sleutel om de inhoud te bekijken." />
            <ChipList />
            <img src="images/swipe-left.svg" className="swipe-image" alt="" />
            <InfoHeader title="Zin in een wedstrijdje?" content="Vind alle sleutels zo snel mogelijk en maak kans op een heerlijk flesje <a href='#'>Essacher Luft</a>!" />
            <InfoHeader details="Er zijn nog <u>23</u> onontdekte sleutels. Vind ze allemaal!" />
            <p className="small warning">Meer dan 1 sleutel getapt? Dan kun je niet meer meedoen.</p>
            <div className="buttons">
                <Link to="/scoreboard"><button className="outline small">Bekijk scorebord</button></Link>
                <Link to="/login"><button className="small">Meedoen</button></Link>
            </div>
        </div>
    );
}