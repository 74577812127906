import React, {useEffect, useState} from "react";
import { InfoHeader}  from "../components/InfoHeader";
import { Link } from "react-router-dom";
import {ScoreboardItem} from "../components/ScoreboardItem";
import moment from "moment";
import classNames from 'classnames/bind';
import styles from '../styles/ScoreboardScreen.module.css';
import axios from "axios";

const cx = classNames.bind(styles);

export function ScoreboardScreen(){
    const [scoreboardItems, setScoreboardItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const userInStorage = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_BASE_URL + '/api/stats/scoreboard')
            .then((response) => {
                setScoreboardItems(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error)
                setLoading(false);
            })
    }, []);

    return (
        <div className={cx('scoreboard-screen')}>
            {(userInStorage && userInStorage.scans.length === 1) ?
                <Link to="/firstscan"><img src="images/back.svg" className="back-image" alt=""/></Link>
                :
                <Link to="/"><img src="images/back.svg" className="back-image" alt=""/></Link>
            }

            {(scoreboardItems[0]) ?
                (<InfoHeader title="Scoreboard" content={`${scoreboardItems.length} mensen hebben alle sleutels al gevonden. <b>${scoreboardItems[0].name}</b> was het snelst. Tijd: ${moment(scoreboardItems[0].scoreTime, "HH:mm:ss").format("H[u]:m[m]:ss")}.`} />)
                :
                (<InfoHeader title="Scoreboard" content="Nog niemand heeft alle chips gescand. Wees snel de eerste!" />)
            }
            {(loading) ?
                (<img src="images/loading.svg" className="loading-image" alt="" />)
                :
                (<></>)
            }

            <div className={cx("score-list")}>
                {
                    scoreboardItems.map((player, index) =>
                        (
                            <ScoreboardItem key={index} rank={index + 1} name={player.name}
                                            time={moment(player.scoreTime, "HH:mm:ss").format("H[u]:m[m]:ss")}
                                            self={userInStorage && userInStorage.id === player.id}/>
                        )
                    )
                }
            </div>
            {(userInStorage && userInStorage.name === "" && userInStorage.scans.length <= 1) ?
                <Link to="/login"><button style={{fontSize: '1.1rem', width: '100%'}}>Meedoen!</button></Link>
                :
                <></>
            }

        </div>
    );
}