import React, {useEffect, useState} from "react";
import { InfoHeader}  from "../components/InfoHeader";
import axios from 'axios';
import { useParams } from "react-router-dom";
import moment from "moment";

export function ScanScreen(){
    const [warning, setWarning] = useState();
    let { chipId } = useParams();
    const userInStorage = JSON.parse(localStorage.getItem('user'));

    function processScan(){
        if(!userInStorage){
            // User doesn't exist yet, creating a cookie for it
            const newUser = {
                name: "",
                email: "",
                scans: [
                    {
                        id: chipId,
                        pivot: {
                            created_at: new Date()
                        }
                    }
                ]
            }

            localStorage.setItem('user', JSON.stringify(newUser));
            window.location.href = process.env.REACT_APP_LOCAL_BASE_URL + '/#/firstscan';
        }

        // Check if the user already scanned something
        if(userInStorage && userInStorage.name === ""){
            let newCookie = userInStorage;
            let found = false;
            newCookie.scans.map(scan => {
                if(scan.id === chipId) found = true;
            });
            if(!found) {
                newCookie.scans.push({
                    id: chipId,
                    pivot: {
                        created_at: new Date()
                    }
                })

                localStorage.setItem('user', JSON.stringify(newCookie));
            }
            window.location.href = process.env.REACT_APP_LOCAL_BASE_URL + '/#/';
        }

        // If it's an actual user, see if the timer hasn't run out yet
        let timerExpired = false;
        if(userInStorage && userInStorage.scans[0] && (3600 - Math.floor((moment() - moment(userInStorage.scans[0].pivot.created_at))/1000) <= 0)){
            timerExpired = true;
        }

        // If timer hasn't run out, execute the post request
        if(!timerExpired) {
            // User is an actual logged in user, add his newest scan to the database
            axios.post(process.env.REACT_APP_API_BASE_URL + '/api/scan/' + chipId, userInStorage)
                .then(response => {
                    localStorage.setItem('user', JSON.stringify(response.data));
                    window.location.href = process.env.REACT_APP_LOCAL_BASE_URL + '/#/';
                })
                .catch(error => {
                    if (error.response) {
                        switch (error.response.status) {
                            case 404:
                                setWarning('Deze chip bestaat niet, waarschijnlijk is hij niet meer geldig.');
                                break;
                            case 401:
                                setWarning('Uw sessie is niet geldig.')
                                break;
                            case 422:
                                setWarning('U heeft deze chip al gescand.')
                                break;
                            default:
                                setWarning('De server is even niet bereikbaar...');
                                break;
                        }
                    } else {
                        setWarning('De server is even niet bereikbaar...');
                    }
                });
        } else {
            // Timer has expired, show warning
            setWarning('De tijd is op! Je mag niet meer scannen.')
        }

    }

    function handleClick(){
        window.open(process.env.REACT_APP_LOCAL_BASE_URL + '/#/', '_self');
    }

    return (
        <div className="scan-screen" onLoad={processScan}>
            {!warning ?
                <InfoHeader title="Scannen..."
                            content="Uw gescande chip wordt opgeslagen. Een moment geduld astublieft."/>
                :
                <>
                    <InfoHeader title="Fout!" />
                    <p className="warning">{warning}</p>
                </>
            }
            {!warning ? <img src="images/loading.svg" className="loading-image" alt="" /> : <></>}
            {warning ? <button onClick={handleClick}>Ga naar home</button> : <></>}
        </div>
    );
}