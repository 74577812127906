import React, { useState, useEffect } from 'react';
import {useParams} from "react-router-dom";
import axios from "axios";
import {InfoHeader} from "../components/InfoHeader";

export function ChipActionScreen(){
    const [message, setMessage] = useState({});
    let { chipId } = useParams();
    const userInStorage = JSON.parse(localStorage.getItem('user'));

    useEffect(() =>{
        // First check if a user exists
        if(userInStorage){
            // Then map all of his scans
            userInStorage.scans.map(cookieScan => {
                // If one of the scans is the same as tje give value in the URL, execute an API call
                if(cookieScan.id === chipId){
                    // Execute the API call
                    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/chip/' + chipId)
                        .then((response) => {
                            let a = response.data.chip.actions[0];
                            // TODO: When there is an update to any of the types, they must be added here
                            switch(a.type){
                                case "text":
                                    setMessage({
                                        title: response.data.chip.title,
                                        text: JSON.parse(a.data).content,
                                        error: false
                                    });
                                    break;
                                case "link":
                                    setMessage({
                                        title: "Moment geduld...",
                                        text: "Je wordt omgeleid",
                                        error: false
                                    });
                                    window.open(JSON.parse(a.data).href,"_blank");
                                    break;
                                case "download":
                                    setMessage({
                                        title: "Moment geduld...",
                                        text: "Bestand wordt gedownload",
                                        error: false
                                    });
                                    window.open(process.env.REACT_APP_API_BASE_URL + "/download?path=" + JSON.parse(a.data).path, "_blank");
                                    break;
                                default:
                                    setMessage({
                                        title: "Fout",
                                        text: "Er is iets misgegaan. Klik op de knop om terug te gaan.",
                                        error: true
                                    });
                            }
                        })
                        .catch(() => {
                            // There is something wrong, user shouldn't know too much
                            setMessage({
                                title: "Fout",
                                text: "Er is iets misgegaan. Klik op de knop om terug te gaan.",
                                error: true
                            });
                        })
                } else {
                    // When the user hasn't scanned the chip yet
                    setMessage({
                        title: "Fout",
                        text: "Je hebt deze chip nog niet gescant",
                        error: true
                    });
                }
            });
        }
    }, []);

    // This function handles the bottom button, so people can return the the home page
    function handleClick(){
        window.open(process.env.REACT_APP_LOCAL_BASE_URL + '/#/', '_self');
    }

    return(
        <div>
            {message !== {} ?
                <InfoHeader title={message.title} content={message.text} />
                :
                <></>
            }
            <button onClick={handleClick} style={{marginBottom: "10px"}}>Ga naar home</button>
        </div>
    );
}