import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from '../styles/InfoHeader.module.css';

const cx = classNames.bind(styles);

export function InfoHeader({title, content, smallcontent: details}){
    //Render all defined props
    return(
        <div className={cx("info-header")}>
            {title && <h1 dangerouslySetInnerHTML={{__html: title}} />}
            {content && <p dangerouslySetInnerHTML={{__html: content}} />}
            {details && <p className="small" dangerouslySetInnerHTML={{__html: details}} />}
        </div>
    );
}

InfoHeader.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    details: PropTypes.string
};