import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from '../styles/ScoreboardScreen.module.css';

const cx = classNames.bind(styles);

export function ScoreboardItem({rank, name, time, self}){
    //Render all defined props
    return(
        <div className={cx("scoreboard-item", {first: rank === 1, second: rank === 2, third: rank === 3}, {self: self})}>
            <div className={cx("scoreboard-item-content")}>
                <p className={cx("rank")}>{rank}</p>
                <p className={cx("name")}>{name}</p>
                <p className={cx("time")}>{time}</p>
            </div>
        </div>
    );
}

ScoreboardItem.propTypes = {
    rank: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    self: PropTypes.bool
};