import React, {useEffect, useState} from 'react';
import { Form } from "@brainstud/universal-components";
import { Input } from "@brainstud/aycl-components";
import { InfoHeader } from "../components/InfoHeader";
import { Link } from "react-router-dom";
import axios from 'axios';

export function LoginScreen(){
    const [processing, setProcessing] = useState(false);
    const [warning, setWarning] = useState();
    const userInStorage = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        if(!userInStorage){
            // User doesn't exist yet, creating a value for it
            const newUser = {
                name: "",
                email: "",
                scans: []
            }

            localStorage.setItem('user', JSON.stringify(newUser));
        }

        if(userInStorage && (userInStorage.name !== "" || (userInStorage.scans && userInStorage.scans.length > 1)))
            window.open(process.env.REACT_APP_LOCAL_BASE_URL + '/#/', '_self');
    }, []);

    function handleClick(e){
        let nameField = document.getElementsByName('name')[0];
        let emailField = document.getElementsByName('email')[0];

        // After every click, the warning should be reset so it's not always in the way
        setWarning('');

        // If a player has scanned more that 1 chip, he should not be able to create an account
        if (userInStorage && userInStorage.scans.length > 1) {
            setWarning('Je hebt meer dan 1 chip gescand. Je mag nu geen account meer maken');
            return;
        }

        // Extra check if all fields are filled in as extra protection
        if(nameField.value && emailField.value){
            // Enable processing, so the buttons are disabled.
            setProcessing(true);

            // Temporary object with the values that should be created or requested
            const newUser = {
                name: nameField.value,
                email: emailField.value,
                scans: userInStorage.scans
            }

            axios.post(process.env.REACT_APP_API_BASE_URL + '/api/player', newUser)
                .then(response => {
                    localStorage.setItem('user', JSON.stringify(response.data));

                    window.location.href = process.env.REACT_APP_LOCAL_BASE_URL + '/#/';
                    setProcessing(false);
                })
                .catch(() => {
                    // Most likely 500 Internal Server Error, user should't know too much
                    setWarning("We konden de server even niet bereiken...");
                    setProcessing(false);
                });

        } else setWarning('Je hebt niet alles ingevuld');
    }

    return (
        <div style={{margin: 30}}>
            {(userInStorage && userInStorage.scans && userInStorage.scans.length === 1) ?
                <Link to="/firstscan"><img src="images/back.svg" className="back-image" alt=""/></Link>
                :
                <></>
            }
            <InfoHeader title="Vertel wie je bent..."/>
            <Form onSubmit={handleClick} validate>
                <Input type="text" name="name" rules="required" placeholder="Naam" disabled={processing}/>
                <Input type="email" name="email" rules="required" placeholder="E-mail adres" disabled={processing}/>
                <p className="small warning">{warning}</p>
                <button type="submit" disabled={processing}>Start de tijd</button>
            </Form>

        </div>
    );
}
