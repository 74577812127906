import React, { useState, useEffect } from 'react';
import { Chip } from './Chip';
import axios from 'axios';
import {InfoHeader} from "./InfoHeader";
import {useCookies} from "react-cookie";
import classNames from 'classnames/bind';
import styles from '../styles/ChipList.module.css';

const cx = classNames.bind(styles);

export function ChipList(){
    const [chips, setChips] = useState([]);
    const [cookies, setCookie] = useCookies();
    const userInStorage = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_BASE_URL + '/api/chip')
            .then((response) => {
                setChips(response.data);
                setCookie("totalChips", response.data.length);
            })
            .catch((error) => {
                console.log(error)
            })
    }, []);

    return(
        <>
            <div className={cx("chip-list")}>
                {
                    chips.map((chip, index) => (
                        <Chip key={index} chipId={chip.id} title={chip.title} image={chip.image} />
                    ))
                }
            </div>
            {
                (userInStorage) ?
                    (<InfoHeader details={`Gevonden: <b>${userInStorage.scans.length}/${chips.length}</b>`} />)
                    :
                    (<InfoHeader details={`Gevonden: <b>0/${chips.length}</b>`} />)
            }
        </>
    );
}