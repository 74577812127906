import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import moment from "moment";
import classNames from 'classnames/bind';
import styles from '../styles/ChipList.module.css';

const cx = classNames.bind(styles);

export function Chip({chipId, title, image}){
    const [scanned, setScanned] = useState(false);
    const [scanTime, setScanTime] = useState("");
    const userInStorage = JSON.parse(localStorage.getItem('user'));

    useEffect(() =>{
        if(userInStorage){
            userInStorage.scans.map(cookieScan => {
                if(cookieScan.id === chipId){
                    setScanned(true);
                    setScanTime(moment(cookieScan.pivot.created_at).format("D-M-YYYY HH:mm [uur]"));
                }
            });
        }
    }, [])

    function getChipAction(){
        window.open(process.env.REACT_APP_LOCAL_BASE_URL+ '/#/action/' + chipId, "_self");
    }

    return(
        <>
            {scanned ?
                (
                    <div className={cx("chip-list-item")} onClick={getChipAction} style={{backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL}${image})`}}>
                        <br/>
                        <p className={cx("title")} >{title}</p>
                        <div className={cx("date")}>
                            <p className={cx("small")} id={cx("date")}>{scanTime}</p>
                        </div>
                    </div>
                )
                :
                (
                    <div className={cx("chip-list-item-not-scanned")}>
                        <img className={cx("chip-list-item-not-scanned")} src="images/question.svg" alt=""/>
                    </div>
                )
            }

        </>
    );
}

Chip.propTypes = {
    chipId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired
};