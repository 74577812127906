import React, {useEffect, useState} from 'react';
import {useCookies} from "react-cookie";
import moment from "moment";
import {InfoHeader} from "./InfoHeader";
import classNames from 'classnames/bind';
import styles from '../styles/Timer.module.css';

const cx = classNames.bind(styles);

export function Timer(){
    const [cookies, setCookie] = useCookies();
    const [secondsLeft, setSecondsLeft] = useState(3600);
    const [timerActive, setTimerActive] = useState(false);
    const userInStorage = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        // Check if there is a logged in user and if that user has any scans
        if(userInStorage && userInStorage.name !== "" && userInStorage.scans[0]){
            // Check if the timer has not reached 0
            if(3600 - Math.floor((moment() - moment(userInStorage.scans[0].pivot.created_at))/1000) > 0){
                setTimerActive(true);
                countdown();
            }
        }
    }, []);

    function countdown(){
        // Count 1 down every second if the timer is > 0
        if(3600 - Math.floor((moment() - moment(userInStorage.scans[0].pivot.created_at))/1000) >= 0){
            if(userInStorage.scans.length === parseInt(cookies.totalChips)){
                setTimerActive(false);
                return;
            }

            setSecondsLeft(3600 - Math.floor((moment() - moment(userInStorage.scans[0].pivot.created_at))/1000));
            setTimeout(countdown, 1000)
        } else {
            setSecondsLeft(0);
        }
    };

    return(
        <>
            {timerActive ?
                <>
                    <div className={cx("timer-component")}>
                        <div className={cx("timer-half")}>
                            <p className="title" style={{fontSize: "3rem", margin: "12px"}}>{moment.duration(secondsLeft, "s").get("m")}</p>
                            <p className="small">min</p>
                        </div>
                        <div className={cx("timer-half")}>
                            <p className="title" style={{fontSize: "3rem", margin: "12px"}}>{moment.duration(secondsLeft, "s").get("s")}</p>
                            <p className="small">sec</p>
                        </div>
                    </div>
                    {(secondsLeft <= 0) ?
                        <InfoHeader title="Tijd is op!" />
                        :
                        <></>
                    }
                </>
                :
                <></>
            }

        </>
    );
}