import React, {useState} from "react";
import { InfoHeader}  from "../components/InfoHeader";
import { useCookies } from 'react-cookie';
import { ChipList } from "../components/ChipList";
import {Timer} from "../components/Timer";
import Modal from 'react-modal';
import axios from "axios";
import classNames from 'classnames/bind';
import styles from '../styles/HomeScreen.module.css';

const cx = classNames.bind(styles);

const modalStyles = {
    overlay: {
        position: 'fixed',
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
    }
};

export function HomeScreen(){
    const [cookies, setCookie] = useCookies();
    const [modalIsOpen,setModalIsOpen] = React.useState(false);
    const userInStorage = JSON.parse(localStorage.getItem('user'));

    if(!userInStorage) window.open(process.env.REACT_APP_LOCAL_BASE_URL + '/#/login', '_self');
    if(userInStorage && (userInStorage.name === "" && userInStorage.scans && userInStorage.scans.length < 2))
        window.open(process.env.REACT_APP_LOCAL_BASE_URL + '/#/firstscan', '_self');

    function openModal(){
        setModalIsOpen(true);
    }

    function closeModal(){
        setModalIsOpen(false);
    }

    function buttonStop(){
        if(userInStorage.name !== "") {
            // There is a logged in user, do delete request
            let postData = userInStorage;
            postData._method = "delete";
            axios.post(process.env.REACT_APP_API_BASE_URL + '/api/player/' + userInStorage.id, userInStorage)
                .then(response => {
                    localStorage.removeItem('user');

                    window.open(process.env.REACT_APP_LOCAL_BASE_URL + '/#/', '_self');
                    setModalIsOpen(false);
                })
                .catch((e) => {
                    // Most likely 500 Internal Server Error, user should't know too much
                    if(e.response.status === 401) console.error("User doesn't have rights to do this...");
                    setModalIsOpen(false);
                });
        } else {
            const newUser = {
                name: "",
                email: "",
                scans: []
            }

            localStorage.setItem('user', JSON.stringify(newUser));
            window.open(process.env.REACT_APP_LOCAL_BASE_URL + '/#/login', '_self');
        }
    }

    return (
        <div style={
             (modalIsOpen) ?
                 {filter: 'blur(8px)'}
                 :
                 {}
             }
             className="home-screen">
            {
                (userInStorage && userInStorage.scans.length > 0) ?
                    (userInStorage.scans.length !== parseInt(cookies.totalChips)) ?
                        <InfoHeader title={`Sleutels gevonden: ${userInStorage.scans.length}`} content={`Nog ${cookies.totalChips - userInStorage.scans.length} te gaan...`} />
                        :
                        <InfoHeader title="Alle sleutels gevonden!" content="Klik <a href='#/scoreboard'>hier</a> om je score te zien!" />
                    :
                    <InfoHeader title="Nog geen chips gevonden" content={`Zoek alle ${cookies.totalChips} chips!`} />
            }
            <ChipList />

            <Modal style={modalStyles} className="ReactModal__Body--open" isOpen={modalIsOpen}>
                <p className={cx("content")}>
                    Weet je zeker dat je wilt stoppen? Je resultaat wordt niet opgeslagen. Al je gegevens worden verwijderd.
                </p>
                <div className={cx("buttons")}>
                    <button onClick={closeModal} className={cx("small", "cancel")}>
                        Nee, doorgaan
                    </button>
                    <button onClick={buttonStop} className={cx("small", "confirm")}>
                        Ja, stoppen
                    </button>
                </div>
            </Modal>
            <Timer/>
            <div style={{margin: '0 10px 0'}}>
                <button style={{width: '100%'}} onClick={openModal}>Stoppen</button>
            </div>
        </div>
    )
}